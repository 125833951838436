import { useState } from 'react';
import ReactDOM from 'react-dom';
import Scrollbar from 'smooth-scrollbar';
import { Navigation, FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

import 'swiper/css/pagination';
import './Popup.scss';
import 'swiper/scss';

import { useEffect, useRef } from 'react';

function Popup({ dataPopup, onClosePopup }) {
  const { t } = useTranslation();
  const popup = useRef();
  const popupScrollBar = useRef(null);
  const refSwiper = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    Scrollbar.init(popupScrollBar.current);
  }, []);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        popup.current.classList.remove('open');
        setTimeout(() => {
          onClosePopup();
        }, 100);
      }
    };

    setTimeout(() => {
      popup.current.classList.add('open');
    }, 100);

    window.addEventListener('keydown', handleEsc);
    openModal();

    return () => {
      closeModal();
      window.removeEventListener('keydown', handleEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClosePopup]);

  useEffect(() => {
    const swiper = refSwiper.current.swiper;

    if (swiper) {
      swiper.on('progress', () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      });
    }
  }, [refSwiper]);

  const closeAnimation = (e) => {
    if (e.currentTarget === e.target) {
      popup.current.classList.remove('open');
      setTimeout(() => {
        onClosePopup();

        if ('ontouchstart' in window || navigator.maxTouchPoints) {
          var touchEndEvent = new Event('touchend');
          e.target.dispatchEvent(touchEndEvent);
        }
      }, 100);
    }
  };

  function getScrollbarWidth() {
    document.body.style.setProperty(
      '--width-scroll',
      `${window.innerWidth - document.documentElement.clientWidth}px`
    );
  }

  function openModal() {
    getScrollbarWidth();
    document.body.classList.add('no-scroll');
  }

  function closeModal() {
    document.body.classList.remove('no-scroll');
  }

  const { title, description, stack, tools, feachers, imgs } = dataPopup;

  return ReactDOM.createPortal(
    <div className='popup' onClick={closeAnimation} ref={popup}>
      <div className='popup__wrapper'>
        <span className='popup__close-btn' onClick={closeAnimation}></span>

        {title ? <h3 className='popup__title'>{t(title)}</h3> : null}

        <div
          className='popup__scroll-content'
          data-scrollbar
          ref={popupScrollBar}
        >
          <div className='scroll-content'>
            <div className='popup__block'>
              {description ? (
                <p className='popup__description'>{t(description)}</p>
              ) : null}

              {stack ? (
                <p className='popup__stack'>
                  <span className='popup__text-title'>{t('Stack')}</span>:{' '}
                  {t(stack)}
                </p>
              ) : null}

              {tools ? (
                <p className='popup__tools'>
                  <span className='popup__text-title'>{t('Tools')}</span>:{' '}
                  {t(tools)}
                </p>
              ) : null}

              {feachers.length > 0 ? (
                <>
                  <p className='popup__feachers-text'>{t('Feachers')}</p>
                  <ul className='popup__feachers-list'>
                    {feachers.map((item, key) => (
                      <li key={key} className='popup__feachers-item'>
                        {t(item)}
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}

              <div className='popup__btns'>
                {dataPopup.href ? (
                  <a
                    className='btn'
                    href={dataPopup.href}
                    target='_blank'
                    rel='noreferrer nofollow noopener'
                  >
                    {t('See website')}
                  </a>
                ) : null}
                {dataPopup.hrefBot ? (
                  <a
                    className='btn'
                    href={dataPopup.hrefBot}
                    target='_blank'
                    rel='noreferrer nofollow noopener'
                  >
                    {t('See bot')}
                  </a>
                ) : null}
                {dataPopup.github ? (
                  <a
                    className='btn btn--black'
                    href={dataPopup.github}
                    target='_blank'
                    rel='noreferrer nofollow noopener'
                  >
                    Github
                  </a>
                ) : null}
              </div>
            </div>
            <div className='popup__imgs-block'>
              <Swiper
                ref={refSwiper}
                modules={[Navigation, FreeMode, Pagination]}
                navigation={true}
                freeMode={{ enabled: false }}
                pagination={{
                  enabled: true,
                  clickable: true,
                }}
                spaceBetween={10}
                slidesPerView={1}
                breakpoints={{
                  500: {
                    pagination: {
                      enabled: false,
                    },
                    spaceBetween: 20,
                    slidesPerView: 'auto',
                  },
                }}
                className='popup__slider-imgs'
              >
                {imgs.map((img, i) => (
                  <SwiperSlide
                    key={i}
                    className={`${
                      img.for
                        ? 'popup__slide popup__slide--' + img.for
                        : 'popup__slide'
                    }`}
                  >
                    <picture className='popup__img'>
                      {img.webp ? (
                        <source srcSet={img.webp} type='image/webp' />
                      ) : null}
                      <img
                        src={img.jpeg}
                        type='image/jpeg'
                        alt={t(title)}
                        title={t(title)}
                      />
                    </picture>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className='popup__slider-controlls'
                style={isBeginning && isEnd ? { display: 'none' } : null}
              >
                <SlidePrevButton
                  disabled={isBeginning}
                  currentSwiper={refSwiper}
                />
                <SlideNextButton disabled={isEnd} currentSwiper={refSwiper} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
}

function SlideNextButton({ disabled, currentSwiper }) {
  return (
    <button
      className='popup__arrow popup__arrow--right'
      onClick={() =>
        currentSwiper.current.swiper &&
        currentSwiper.current.swiper?.slideNext()
      }
      disabled={disabled}
    ></button>
  );
}

function SlidePrevButton({ disabled, currentSwiper }) {
  return (
    <button
      className='popup__arrow popup__arrow--left'
      onClick={() =>
        currentSwiper.current.swiper &&
        currentSwiper.current.swiper?.slidePrev()
      }
      disabled={disabled}
    ></button>
  );
}

export default Popup;
