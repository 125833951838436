import { useTranslation } from 'react-i18next';
import { Button } from 'react-scroll';
import './Contact.scss';

function Contact() {
  const { t } = useTranslation();

  return (
    <div className='contact' id='contacts' data-animation>
      <div className='container'>
        <div className='contact__block'>
          <h2 className='contact__title' data-typed-animation>
            {t('Contact')}
          </h2>
          <p className='contact__description'>{t('Contact-description')}</p>
          <ul className='contact__list'>
            <li className='contact__item contact__item--whatsapp'>
              <p className='contact__item-text'>
                {t('Phone')} / WhatsApp:{' '}
                <a
                  href='https://wa.me/34617455769'
                  target='_blank'
                  rel='noreferrer'
                >
                  +34&nbsp;617&nbsp;455&nbsp;769
                </a>
              </p>
            </li>
            <li className='contact__item contact__item--telegram'>
              <p className='contact__item-text'>
                Telegram:{' '}
                <a href='https://t.me/nisvem' target='_blank' rel='noreferrer'>
                  @nisvem
                </a>
              </p>
            </li>
            <li className='contact__item contact__item--email'>
              <p className='contact__item-text'>
                Email: <a href='mailto:nisvem@gmail.com'>nisvem@gmail.com</a>
              </p>
            </li>
            <li className='contact__item contact__item--linkedin'>
              <p className='contact__item-text'>
                LikedIn:{' '}
                <a
                  href='https://www.linkedin.com/in/nisvem/'
                  target='_blank'
                  rel='noreferrer'
                >
                  https://www.linkedin.com/in/nisvem/
                </a>
              </p>
            </li>
          </ul>
          <div className='contact__nav-block'>
            <nav className='contact__nav contact-nav'>
              <ul className='contact-nav__list'>
                <li className='contact-nav__item'>
                  <Button
                    to='about'
                    smooth={true}
                    duration={1000}
                    offset={-50}
                    className='item-link'
                  >
                    {t('About')}
                  </Button>
                </li>
                <li className='contact-nav__item'>
                  <Button
                    to='skills'
                    smooth={true}
                    duration={1000}
                    offset={-50}
                    className='item-link'
                  >
                    {t('Skills')}
                  </Button>
                </li>
                <li className='contact-nav__item'>
                  <Button
                    to='experience'
                    smooth={true}
                    duration={1000}
                    offset={-50}
                    className='item-link'
                  >
                    {t('Experience')}
                  </Button>
                </li>
                <li className='contact-nav__item'>
                  <Button
                    to='portfolio'
                    smooth={true}
                    offset={-50}
                    duration={1000}
                    className='item-link'
                  >
                    {t('Portfolio')}
                  </Button>
                </li>
                <li className='contact-nav__item'>
                  <Button
                    to='contacts'
                    smooth={true}
                    duration={1000}
                    offset={-50}
                    className='item-link'
                  >
                    {t('Contact')}
                  </Button>
                </li>
              </ul>
            </nav>
            <p className='contact__text-design-by'>
              {t('Design by')}{' '}
              <a
                href='https://t.me/mc_shap/'
                target='_blank'
                rel='noreferrer nofollow noopener'
              >
                m.c.sha
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
