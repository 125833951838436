import { useTranslation } from 'react-i18next';
import './Skills.scss';
import dataOfSkills from './DataSkills.json';

function Skills() {
  const { t } = useTranslation();

  const TypeOfSkills = (data) => {
    const ElementsOfScale = (n) => {
      return [...Array(3 * n)].map((el, i) => {
        return (
          <div
            className='skill-scale__element'
            style={{ animationDelay: Math.ceil(i * 100) + 'ms' }}
            key={i}
          ></div>
        );
      });
    };

    return data.map((el, i) => {
      if (!el.title || !el.arrayStack) return false;

      const ListOfSkills = () => {
        return Object.keys(el.arrayStack).map((key, index) => {
          return (
            <li className='skills__item skill' key={index}>
              <div className='skill__block skill-scale'>
                {ElementsOfScale(el.arrayStack[key])}
              </div>
              <p className='skill__title'>{key}</p>
            </li>
          );
        });
      };

      return (
        <div className='skills__type' key={i}>
          <p className='skills__type-title'>{el.title}</p>
          <ul className='skills__list'>{ListOfSkills()}</ul>
        </div>
      );
    });
  };

  return (
    <div className='skills' id='skills' data-animation>
      <div className='skills__wrapper'>
        <div className='container'>
          <h2 className='skills__title' data-typed-animation >
            {t('Skills')}
          </h2>
          <div className='skills__block' data-animation>{TypeOfSkills(dataOfSkills)}</div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
