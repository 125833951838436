import React from "react";
import ReactDOM from "react-dom/client";
import './i18n';
import App from "./components/App/App";

import "./style/style.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <App/>
);
