import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  RU: {
    translation: {
      BykovArtem: 'Быков Артём',
      About: 'Обо мне',
      Skills: 'Навыки',
      Experience: 'Опыт работы',
      Competence: 'Компетенции',
      Portfolio: 'Портфолио',
      Contact: 'Контакты',
      'My strengths':
        'Сильные стороны: тайм менеджмент, ответственность и решение нестандартных задач',
      'These are the qualities ...':
        'Те качества, которые отмечал каждый руководитель при работе со мной',
      Now: 'Сейчас',
      Value: 'Ценности',
      Future: 'В будущем',
      'At free time': 'В свободное время',
      'I live in Spain ...':
        'Я проживаю в Испании и имею разрешение на работу. В настоящее время ищу возможности для удаленной работы в Европе и с перспективой профессионального развития',
      'In work, I appreciate ...':
        'В профессиональной деятельности я ценю структурированность, эффективную коммуникацию и четкую организацию рабочего процесса, где каждый участник команды имеет ясное представление о своих задачах и ответственности',
      'I will continue to deepen my knowledge ...':
        'Я планирую дальше развивать свои компетенции в области фронтенд-разработки и активно участвовать в создании уникальных продуктов. Моя цель — найти такую работу, где я смогу не только совершенствовать свои умения, но и вносить значимый вклад в успех команды',
      'I like to travel, swim ...':
        'Люблю путешествия, плавание, просмотр фильмов с захватывающими сюжетами, общение с друзьями и разгадывание головоломок',
      'Download CV': 'Скачать резюме',
      'Main tasks': 'Основные задачи',
      'My works results': 'Результаты работы',
      MGCom:
        'MGCom | Цифровое агентство с акцентом на маркетинг | Москва, Россия',
      'MGCom experience': 'Март 2021 — Настоящий момент (3,5+ года)',
      'MGCom_main-1':
        'Создание лендингов с адаптивной и кроссбраузерной версткой',
      'MGCom_main-2': 'Создание и изменение верстки компонентов сайтов',
      'MGCom_main-3': 'Оптимизация скорости загрузки страниц',
      'MGCom_main-4': 'Создание и модификация сайтов на Tilda',
      'MGCom_main-5': 'Создание телеграм-ботов',
      'MGCom_result-1':
        'Повлиял на увеличение количество выигранных тендеров на 30% за счет внедрения современных технологий и оптимизации скорости загрузки страниц.',
      'MGCom_result-2':
        'Успешно способствовал открытию нового канала привлечения клиентов, разработав телеграм-ботов для маркетинговых целей. За последние два года мы разработали 10-15 ботов.',
      'MGCom_result-3':
        'Быстро освоил платформу Tilda, что теперь составляет 10-15% всех проектов компании, что позволило расширить спектр услуг и привлечь новых клиентов.',
      'MGCom_result-4':
        'За время работы количество клиентов увеличилось в 3-4 раза, так как мы с командой успешно справлялись с постевленными задачами',
      AMBER:
        'AMBER | Цифровое агентство, специализирующееся на SEO-продвижении сайтов | Санкт-Петербург, Россия',
      'AMBER experience': 'Февраль 2019 — Март 2021 (2 года 1 месяц)',
      'AMBER_main-1':
        'Составлял технический аудит сайта и предоставлял рекомендаций по улучшению показателей, а также рекомендаций по улучшению скорости загрузки сайта',
      'AMBER_main-2':
        'Подготовка технических заданий для копирайтеров и проверка полученных SEO-текстов для сайта',
      'AMBER_main-3': 'Настройка аналитики на сайтах',
      'AMBER_main-4': 'Обучал новых сотрудников',
      'AMBER_main-5':
        'Управлял проектами и распределял задачи между сотрудниками',
      'AMBER_result-1':
        'Создал чек-лист для проверки сайта на SEO-оптимизацию, что позволило ускорить процесс проверки сайтов по стандартным критериям.',
      'AMBER_result-2':
        'В течение двух лет руководил командой из 2-4 специалистов, в зависимости от загрузки, одновременно курируя 15-20 проектов.',
      'AMBER_result-3':
        'Мы совместно помогли улучшить позиции сайтов клиентов в поисковой выдаче на 10-30%, в зависимости от сложности и конкурентности ниши.',
      'View Case': 'Посмотреть кейс',
      'See website': 'Перейти на сайт',
      'See bot': 'Посмотреть бота',
      'Contact-description':
        'Если вы находитесь в поиске фронтенд-разработчика обращайтесь ко мне:',
      Phone: 'Телефон',
      'Design by': 'За дизайн спасибо',
      Stack: 'Стек',
      Tools: 'Инструменты',
      Feachers: 'Особенности',
      'Portfolio-1-title': '80 Level Cloud Experiences',
      'Portfolio-1-description': 'Лендинг для платформы 80 Level',
      'Portfolio-1-text':
        'Коммерческий проект. Лендинг для платформы 80 Level. Сама платформа разработана для создателей контента на Unreal Engine 5, где они могут выкладывать свои 3D-проекты.',
      'Portfolio-1-feachers-1':
        'Адаптивность для идеального отображения на разных устройствах',
      'Portfolio-1-feachers-2':
        'Кроссбраузерность для стабильной работы во всех основных браузерах',
      'Portfolio-1-feachers-3':
        'Высокая производительность благодаря оптимизации загрузки и изображений',
      'Portfolio-1-feachers-4':
        'Интеграция Metaframe в проект была осуществлена с помощью использования React и API клиента',

      'Portfolio-2-title': '80 Level Metasites Challenge',
      'Portfolio-2-description': 'Лендинг для платформы 80 Level',
      'Portfolio-2-text':
        'Коммерческий проект. Лендинг для платформы 80 Level, чтобы сборать заявки для конкурса. Сама платформа разработана для создателей контента на Unreal Engine 5, где они могут выкладывать свои 3D-проекты.',
      'Portfolio-2-feachers-1':
        'Адаптивность для идеального отображения на разных устройствах',
      'Portfolio-2-feachers-2':
        'Кроссбраузерность для стабильной работы во всех основных браузерах',
      'Portfolio-2-feachers-3':
        'Высокая производительность благодаря оптимизации загрузки и изображений',
      'Portfolio-2-feachers-4':
        'Интеграция Metaframe в проект была осуществлена с помощью использования React и API клиента',

      'Portfolio-3-title': 'Marvel Information Portal',
      'Portfolio-3-description':
        'Информационный портал по героям и комиксам Marvel',
      'Portfolio-3-text':
        'Pet-проект создавался для улучшения навыков React и изучения инструментов axios, MUI, styled-omponents, а также для углубленного изучения работы API',
      'Portfolio-3-feachers-1':
        'Во время получения данных с сервера отображается индикатор загрузки',
      'Portfolio-3-feachers-2':
        'Обработка некорректных ответов сервера с отображением соответствующих заглушек',
      'Portfolio-3-feachers-3':
        'Динамическая подгрузка контента при прокрутке страницы',

      'Portfolio-4-title': 'Wheather Map',
      'Portfolio-4-description':
        'Интерактивная карта погоды, позволяющая в реальном времени отслеживать погоду по всему миру',
      'Portfolio-4-text':
        'Pet-проект был разработан с целью усовершенствования навыков работы с React, изучения функциональных возможностей Mapbox и глубокого погружения в взаимодействие с API. Интерфейс Mapbox предоставляет возможность получения координат при клике на карту, а с использованием Weather API реализована функция отображения погоды в выбранной точке.',
      'Portfolio-4-feachers-1':
        'Во время получения данных с сервера отображается индикатор загрузки',

      'Portfolio-5-title': 'Отдых в Дубае',
      'Portfolio-5-description':
        'Лендинг для конкурса, организованного компанией "Детский Мир"',
      'Portfolio-5-text':
        'Коммерческий проект. Лендинг для конкурса, успешно проведенного компанией "Детский Мир". В рамках конкурса участники проходили тест, результаты которого позволяли получить фрагменты изображения. Эти фрагменты необходимо было собрать в единую картинку и опубликовать в социальной сети ВКонтакте.',
      'Portfolio-5-feachers-1':
        'Адаптивность для идеального отображения на разных устройствах',
      'Portfolio-5-feachers-2':
        'Кроссбраузерность для стабильной работы во всех основных браузерах',
      'Portfolio-5-feachers-3':
        'Высокая производительность благодаря оптимизации загрузки и изображений',
      'Portfolio-5-feachers-4':
        'Возможность поделиться в соцсетях полученной картинкой',

      'Portfolio-6-title': 'Telegram bot Mr.Doors',
      'Portfolio-6-description':
        'Telegram-бот, разработанный для компании Mr.Doors',
      'Portfolio-6-text':
        'Коммерческий проект. Telegram-бот для компании Mr.Doors, предназначенный для автоматизации сбора заявок.',
      'Portfolio-6-feachers-1':
        'Все поступающие заявки автоматически записываются в базу данных и одновременно пересылаются на указанный электронный адрес',
      'Portfolio-6-feachers-2': 'В боте интегрирована Google Analytics',
      'Portfolio-6-feachers-3':
        'В боте используется Telegram Web Apps для более удобного сбора данных',

      'Portfolio-7-title': 'Nisvem VPN Bot',
      'Portfolio-7-description':
        'Бот для получения ключей к VPN-серверам через приложение Outline',
      'Portfolio-7-text':
        'Комерческий pet-проект. Бот с webapp с помощью которого можно получить и контролировать свои ключи к VPN-серверам на базе Outline',
      'Portfolio-7-feachers-1':
        'Возможность получения ключей к VPN-серверам на базе Outline через rest api',
      'Portfolio-7-feachers-2': 'Webapp создан с помощью React',
      'Portfolio-7-feachers-3':
        'Серверная часть написана на Node.js и express.js',
      'Portfolio-7-feachers-4':
        'Вся информация о пользователях хранится в базе данных MongoDB',
      'Portfolio-7-feachers-5':
        'Есть версия webapp для администратора, где он может управлять пользователями и ключами',
      'Portfolio-7-feachers-6':
        'Есть возможность добавлять новые сервера в приложение',
      'Portfolio-7-feachers-7':
        'Оплата VPN ключей осуществляется с помощью Yoomoney',
    },
  },
  EN: {
    translation: {
      BykovArtem: 'Bykov Artem',
      About: 'About',
      Skills: 'Skills',
      Experience: 'Experience',
      Competence: 'Competence',
      Portfolio: 'Portfolio',
      Contact: 'Contacts',
      'My strengths':
        'My strengths: time management, responsibility and solving non-standard tasks',
      'These are the qualities ...':
        'These are the qualities that every manager has noted when working with me',
      Now: 'Now',
      Value: 'Value',
      Future: 'In Future',
      'I live in Spain ...':
        'I reside in Spain and have a work permit. Currently, I am seeking remote work opportunities in Europe with prospects for professional development',
      'In work, I appreciate ...':
        'In my professional activities, I value structure, effective communication, and clear organization of the work process, where each team member has a clear understanding of their tasks and responsibilities',
      'I will continue to deepen my knowledge ...':
        "I plan to further develop my competencies in front-end development and actively participate in the creation of unique products. My goal is to find a job where I can not only improve my skills but also make a significant contribution to the team's success",
      'I like to travel, swim ...':
        'I enjoy traveling, swimming, watching movies with intriguing plots, socializing with friends, and solving puzzles',
      'Download CV': 'Download CV',
      'Main tasks': 'Main tasks',
      'My works results': "My work's results",
      MGCom:
        'MGCom | Digital agency with a focus on marketing | Moscow, Russia',
      'MGCom experience': 'Mar 2021 - Present (3,5+ years)',
      'MGCom_main-1':
        'Developing landing pages with responsive and cross-browser layout',
      'MGCom_main-2': 'Creating and updating website components',
      'MGCom_main-3': 'Optimizing page loading speed',
      'MGCom_main-4': 'Building and modifying websites using Tilda',
      'MGCom_main-5': 'Developing Telegram bots',
      'MGCom_result-1':
        'Contributed to a 30% increase in successful tenders by implementing modern technologies and optimizing page loading speeds',
      'MGCom_result-2':
        'Successfully opened a new customer acquisition channel by developing Telegram bots for marketing purposes, delivering 10–15 bots over the past two years',
      'MGCom_result-3':
        "Quickly mastered the Tilda platform, which now accounts for 10–15% of the company's projects, expanding the service portfolio and attracting new clients",
      'MGCom_result-4':
        'Helped increase the customer base by 3–4 times by effectively handling assigned tasks as part of a dedicated team',
      AMBER:
        'AMBER | Digital agency focused on SEO promoting websites | St. Petersburg, Russia',
      'AMBER experience': 'Feb 2019 - Mar 2021 (2 years 1 month)',
      'AMBER_main-1':
        'Conducted technical site audits and provided recommendations for improving performance and loading speed',
      'AMBER_main-2':
        'Prepared technical specifications for copywriters and reviewed SEO texts for websites',
      'AMBER_main-3': 'Configured analytics on websites',
      'AMBER_main-4': 'Trained new employees',
      'AMBER_main-5': 'Managed projects and delegated tasks among team members',
      'AMBER_result-1':
        'Developed a checklist for website SEO optimization, speeding up the evaluation process based on standard criteria',
      'AMBER_result-2':
        'Led a team of 2–4 specialists (depending on workload) and simultaneously managed 15–20 projects',
      'AMBER_result-3':
        "Helped improve clients' website rankings in search results by 10–30%, depending on the niche's complexity and competitiveness",
      'View Case': 'View Case',
      'See website': 'Go to website',
      'See bot': 'View the bot',
      'Contact-description':
        'If you are looking for a front-end developer, contact me:',
      Phone: 'Phone',
      'Design by': 'For design thanks',
      Stack: 'Stack',
      Tools: 'Tools',
      Feachers: 'Features',
      'Portfolio-1-title': '80 Level Cloud Experiences',
      'Portfolio-1-description': 'Landing page for 80 Level platform',
      'Portfolio-1-text':
        'A commercial project. Landing page for 80 Level platform. The platform itself is designed for content creators on Unreal Engine 5, where they can post their 3D projects.',
      'Portfolio-1-feachers-1':
        'Responsiveness for perfect display on different devices',
      'Portfolio-1-feachers-2':
        'Cross-browser compatibility for stable operation in all major browsers',
      'Portfolio-1-feachers-3':
        'High performance thanks to optimization of loading and images',
      'Portfolio-1-feachers-4':
        'Integration of Metaframe into the project was carried out using React and client API',

      'Portfolio-2-title': '80 Level Metasites Challenge',
      'Portfolio-2-description': 'Landing page for 80 Level platform',
      'Portfolio-2-text':
        'A commercial project. Landing page for 80 Level platform, aimed at gathering applications for a competition. The platform is designed for content creators on Unreal Engine 5, where they can showcase their 3D projects.',
      'Portfolio-2-feachers-1':
        'Responsiveness for perfect display on various devices',
      'Portfolio-2-feachers-2':
        'Cross-browser compatibility for stable operation across all major browsers',
      'Portfolio-2-feachers-3':
        'High performance due to optimization of loading and images',
      'Portfolio-2-feachers-4':
        'Integration of Metaframe into the project was achieved using React and client API',

      'Portfolio-3-title': 'Marvel Information Portal',
      'Portfolio-3-description':
        'An information portal about Marvel heroes and comics',
      'Portfolio-3-text':
        'This pet-project was created to improve React skills and to learn tools like Axios, MUI, styled-components, as well as to gain in-depth understanding of API interaction.',
      'Portfolio-3-feachers-1':
        'A loading indicator is displayed while data is being fetched from the server',
      'Portfolio-3-feachers-2':
        'Handling of incorrect server responses with the display of appropriate placeholders',
      'Portfolio-3-feachers-3':
        'Dynamic content loading as the page is scrolled',

      'Portfolio-4-title': 'Weather Map',
      'Portfolio-4-description':
        'An interactive weather map that allows real-time tracking of weather conditions worldwide',
      'Portfolio-4-text':
        'This pet-project was developed to enhance React skills, explore the functionalities of Mapbox, and delve deep into API interactions. The Mapbox interface enables the retrieval of coordinates with a map click, and weather information at the selected location is displayed using the Weather API.',
      'Portfolio-4-feachers-1':
        'A loading indicator is displayed while data is being fetched from the server',

      'Portfolio-5-title': 'Vacation in Dubai',
      'Portfolio-5-description':
        "Landing page for a contest organized by 'Detsky Mir' company",
      'Portfolio-5-text':
        "Commercial project. A landing page for a contest successfully held by 'Detsky Mir' company. During the contest, participants went through a quiz, the results of which allowed them to receive image fragments. These fragments had to be assembled into a complete picture and published on the VKontakte social network.",
      'Portfolio-5-feachers-1':
        'Responsiveness for perfect display on various devices',
      'Portfolio-5-feachers-2':
        'Cross-browser compatibility for stable operation in all major browsers',
      'Portfolio-5-feachers-3':
        'High performance due to optimization of loading and images',
      'Portfolio-5-feachers-4':
        'Ability to share the obtained picture on social networks',

      'Portfolio-6-title': 'Telegram Bot Mr.Doors',
      'Portfolio-6-description':
        'A Telegram bot developed for Mr.Doors company',
      'Portfolio-6-text':
        'Commercial project. A Telegram bot designed for Mr.Doors, aimed at automating the collection of applications.',
      'Portfolio-6-feachers-1':
        'All incoming applications are automatically recorded in the database and simultaneously forwarded to a specified email address',
      'Portfolio-6-feachers-2': 'Google Analytics is integrated into the bot',
      'Portfolio-6-feachers-3':
        'The bot utilizes Telegram Web Apps for more convenient data collection',

      'Portfolio-7-title': 'Nisvem VPN Bot',
      'Portfolio-7-description':
        'Bot for obtaining keys to VPN servers through the Outline application',
      'Portfolio-7-text':
        'Commercial pet project. A bot with a webapp that allows you to obtain and control your keys to VPN servers based on Outline',
      'Portfolio-7-feachers-1':
        'Ability to obtain keys to Outline-based VPN servers via REST API',
      'Portfolio-7-feachers-2': 'Webapp created using React',
      'Portfolio-7-feachers-3': 'Server-side written in Node.js and express.js',
      'Portfolio-7-feachers-4':
        'All user information is stored in a MongoDB database',
      'Portfolio-7-feachers-5':
        'There is an admin version of the webapp where the administrator can manage users and keys',
      'Portfolio-7-feachers-6': 'Ability to add new servers to the application',
      'Portfolio-7-feachers-7': 'Payment for VPN keys is made using Yoomoney',
    },
  },
  ES: {
    translation: {
      BykovArtem: 'Artem Bykov',
      About: 'Sobre mí',
      Skills: 'Habilidades',
      Experience: 'Experiencia',
      Competence: 'Competencias',
      Portfolio: 'Portafolio',
      Contact: 'Contactos',
      'My strengths':
        'Puntos fuertes: gestión del tiempo, responsabilidad y resolución de tareas no convencionales',
      'These are the qualities ...':
        'Cualidades que cada gerente notó al trabajar conmigo',
      Now: 'Actualmente',
      Value: 'Valores',
      Future: 'En el futuro',
      'At free time': 'En mi tiempo libre',
      'I live in Spain ...':
        'Resido en España y tengo permiso de trabajo. Actualmente, busco oportunidades de trabajo remoto en Europa con perspectivas de desarrollo profesional',
      'In work, I appreciate ...':
        'En mi actividad profesional, valoro la estructuración, la comunicación efectiva y una organización clara del proceso laboral, donde cada integrante del equipo tiene una comprensión clara de sus tareas y responsabilidades',
      'I will continue to deepen my knowledge ...':
        'Planeo seguir desarrollando mis competencias en el desarrollo front-end y participar activamente en la creación de productos únicos. Mi objetivo es encontrar un trabajo donde no solo pueda mejorar mis habilidades, sino también contribuir significativamente al éxito del equipo',
      'I like to travel, swim ...':
        'Me gusta viajar, nadar, ver películas con tramas intrigantes, socializar con amigos y resolver rompecabezas',
      'Download CV': 'Descargar CV',
      'Main tasks': 'Tareas principales',
      'My works results': 'Resultados de mi trabajo',
      MGCom: 'MGCom | Agencia digital centrada en marketing | Moscú, Rusia',
      'MGCom experience': 'Marzo 2021 — Presente (3,5+ años)',
      'MGCom_main-1':
        'Creación de landing pages con diseño adaptable y compatibilidad entre navegadores',
      'MGCom_main-2': 'Creación y modificación de componentes de páginas web',
      'MGCom_main-3': 'Optimización de la velocidad de carga de páginas',
      'MGCom_main-4': 'Creación y modificación de sitios web en Tilda',
      'MGCom_main-5': 'Desarrollo de bots para Telegram',
      'MGCom_result-1':
        'Contribuí a aumentar el número de licitaciones ganadas en un 30% mediante la implementación de tecnologías modernas y la optimización de la velocidad de carga de páginas.',
      'MGCom_result-2':
        'Abrí con éxito un nuevo canal de captación de clientes al desarrollar bots de Telegram con fines de marketing. En los últimos dos años, desarrollamos entre 10 y 15 bots.',
      'MGCom_result-3':
        'Aprendí rápidamente a usar la plataforma Tilda, que ahora representa el 10-15% de todos los proyectos de la empresa, lo que amplió la gama de servicios y atrajo a nuevos clientes.',
      'MGCom_result-4':
        'Durante mi tiempo en la empresa, el número de clientes aumentó de 3 a 4 veces, ya que mi equipo y yo cumplimos con éxito las tareas asignadas.',
      AMBER:
        'AMBER | Agencia digital especializada en SEO para sitios web | San Petersburgo, Rusia',
      'AMBER experience': 'Febrero 2019 — Marzo 2021 (2 años y 1 mes)',
      'AMBER_main-1':
        'Realización de auditorías técnicas de sitios web y recomendaciones para mejorar el rendimiento y la velocidad de carga.',
      'AMBER_main-2':
        'Preparación de tareas técnicas para redactores y revisión de textos SEO para sitios web.',
      'AMBER_main-3': 'Configuración de herramientas analíticas en sitios web.',
      'AMBER_main-4': 'Capacitación de nuevos empleados.',
      'AMBER_main-5':
        'Gestión de proyectos y distribución de tareas entre los empleados.',
      'AMBER_result-1':
        'Creé una lista de verificación para la optimización SEO de sitios web, lo que aceleró el proceso de evaluación basado en criterios estándar.',
      'AMBER_result-2':
        'Durante dos años lideré un equipo de 2 a 4 especialistas, dependiendo de la carga de trabajo, supervisando simultáneamente de 15 a 20 proyectos.',
      'AMBER_result-3':
        'Ayudamos a mejorar la posición de los sitios web de los clientes en los resultados de búsqueda entre un 10 y un 30%, dependiendo de la complejidad y competitividad del nicho.',

      'View Case': 'Ver caso',
      'See website': 'Visitar sitio web',
      'See bot': 'Ver el bot',
      'Contact-description':
        'Si buscas un desarrollador front-end, no dudes en contactarme:',
      Phone: 'Teléfono',
      Stack: 'Stack Tecnológico',
      Tools: 'Herramientas',
      Feachers: 'Características',
      'Design by': 'Diseñado por',
      'Portfolio-1-title': '80 Level Cloud Experiences',
      'Portfolio-1-description':
        'Página de destino para la plataforma 80 Level',
      'Portfolio-1-text':
        'Un proyecto comercial. Página de destino para la plataforma 80 Level. La propia plataforma está diseñada para creadores de contenido en Unreal Engine 5, donde pueden publicar sus proyectos 3D.',
      'Portfolio-1-feachers-1':
        'Adaptabilidad para una visualización perfecta en diferentes dispositivos',
      'Portfolio-1-feachers-2':
        'Compatibilidad entre navegadores para un funcionamiento estable en todos los navegadores principales',
      'Portfolio-1-feachers-3':
        'Alto rendimiento gracias a la optimización de la carga y las imágenes',
      'Portfolio-1-feachers-4':
        'La integración de Metaframe en el proyecto se realizó utilizando React y la API del cliente',

      'Portfolio-2-title': '80 Level Metasites Challenge',
      'Portfolio-2-description':
        'Página de aterrizaje para la plataforma 80 Level',
      'Portfolio-2-text':
        'Un proyecto comercial. Página de aterrizaje para la plataforma 80 Level, para recopilar solicitudes para un concurso. La plataforma está diseñada para creadores de contenido en Unreal Engine 5, donde pueden publicar sus proyectos 3D.',
      'Portfolio-2-feachers-1':
        'Adaptabilidad para una visualización perfecta en diversos dispositivos',
      'Portfolio-2-feachers-2':
        'Compatibilidad con varios navegadores para un funcionamiento estable en todos los principales',
      'Portfolio-2-feachers-3':
        'Alto rendimiento gracias a la optimización de la carga y las imágenes',
      'Portfolio-2-feachers-4':
        'La integración de Metaframe en el proyecto se logró mediante el uso de React y la API del cliente',
      'Portfolio-3-title': 'Portal de Información de Marvel',
      'Portfolio-3-description':
        'Un portal informativo sobre los héroes y cómics de Marvel',
      'Portfolio-3-text':
        'Este proyecto personal fue creado para mejorar habilidades en React y aprender herramientas como Axios, MUI, styled-components, además de profundizar en la interacción con API.',
      'Portfolio-3-feachers-1':
        'Se muestra un indicador de carga mientras se obtienen datos del servidor',
      'Portfolio-3-feachers-2':
        'Manejo de respuestas incorrectas del servidor con la visualización de placeholders apropiados',
      'Portfolio-3-feachers-3':
        'Carga dinámica de contenido al desplazar la página',

      'Portfolio-4-title': 'Mapa del Tiempo',
      'Portfolio-4-description':
        'Un mapa interactivo del tiempo que permite el seguimiento en tiempo real de las condiciones meteorológicas en todo el mundo',
      'Portfolio-4-text':
        'Este proyecto personal fue desarrollado para mejorar habilidades en React, explorar las funcionalidades de Mapbox y profundizar en la interacción con las API. La interfaz de Mapbox ofrece la capacidad de obtener coordenadas al hacer clic en el mapa, y mediante el uso de la API de Weather, se implementó la función de mostrar el tiempo meteorológico en el punto seleccionado.',
      'Portfolio-4-feachers-1':
        'Se muestra un indicador de carga mientras se obtienen datos del servidor',

      'Portfolio-5-title': 'Vacaciones en Dubái',
      'Portfolio-5-description':
        "Página de aterrizaje para un concurso organizado por la compañía 'Detsky Mir'",
      'Portfolio-5-text':
        "Proyecto comercial. Página de aterrizaje para un concurso exitosamente llevado a cabo por la compañía 'Detsky Mir'. En el marco del concurso, los participantes realizaban un test, cuyos resultados les permitían obtener fragmentos de una imagen. Estos fragmentos debían ser ensamblados en una imagen completa y publicados en la red social VKontakte.",
      'Portfolio-5-feachers-1':
        'Adaptabilidad para una visualización perfecta en diversos dispositivos',
      'Portfolio-5-feachers-2':
        'Compatibilidad con varios navegadores para un funcionamiento estable en todos los principales',
      'Portfolio-5-feachers-3':
        'Alto rendimiento gracias a la optimización de la carga y las imágenes',
      'Portfolio-5-feachers-4':
        'Posibilidad de compartir la imagen obtenida en redes sociales',

      'Portfolio-6-title': 'Bot de Telegram para Mr.Doors',
      'Portfolio-6-description':
        'Un bot de Telegram desarrollado para la empresa Mr.Doors',
      'Portfolio-6-text':
        'Proyecto comercial. Un bot de Telegram diseñado para Mr.Doors, destinado a automatizar la recolección de solicitudes.',
      'Portfolio-6-feachers-1':
        'Todas las solicitudes recibidas se registran automáticamente en la base de datos y se reenvían simultáneamente a una dirección de correo electrónico especificada',
      'Portfolio-6-feachers-2': 'Google Analytics está integrado en el bot',
      'Portfolio-6-feachers-3':
        'El bot utiliza Telegram Web Apps para una recolección de datos más conveniente',

      'Portfolio-7-title': 'Bot VPN Nisvem',
      'Portfolio-7-description':
        'Bot para obtener claves de servidores VPN a través de la aplicación Outline',
      'Portfolio-7-text':
        'Proyecto personal comercial. Un bot con una aplicación web que permite obtener y controlar tus claves para servidores VPN basados en Outline',
      'Portfolio-7-feachers-1':
        'Capacidad de obtener claves para servidores VPN basados en Outline a través de API REST',
      'Portfolio-7-feachers-2': 'Aplicación web creada con React',
      'Portfolio-7-feachers-3':
        'Lado del servidor escrito en Node.js y express.js',
      'Portfolio-7-feachers-4':
        'Toda la información del usuario se almacena en una base de datos MongoDB',
      'Portfolio-7-feachers-5':
        'Existe una versión de administrador de la aplicación web donde el administrador puede gestionar usuarios y claves',
      'Portfolio-7-feachers-6':
        'Posibilidad de añadir nuevos servidores a la aplicación',
      'Portfolio-7-feachers-7':
        'El pago de las claves VPN se realiza mediante Yoomoney',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lng') ? localStorage.getItem('lng') : 'EN', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
