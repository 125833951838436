import { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Icon } from "@iconify/react";

import "./BtnUp.scss";

function BtnUp() {
  useEffect(() => {
    const onScroll = () => {
      const btn = document.getElementById("btn");
      const topPos = document.documentElement.scrollTop;
  
      if (topPos > 300) {
        btn.classList.add("btn-up--show");
      } else {
        btn.classList.remove("btn-up--show");
      }
    };

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onSctollTop = () => scroll.scrollToTop({
    duration: 500,
    smooth: true,
  });

  return (
    <button className="btn-up" onClick={onSctollTop} id="btn">
      <Icon icon="ep:arrow-up-bold" width="25" height="25"/>
    </button>
  )
}


export default BtnUp;