import { useTranslation } from 'react-i18next';
import './Experience.scss';
import dataOfExperience from './DataExperience.json';

function Experience() {
  const { t } = useTranslation();

  const tableOfJobs = (data) => {
    return (
      <div className='experience__table exp-table'>
        <div className='exp-table__header'>
          <div className='exp-table__title--space'></div>
          <p className='exp-table__title'>{t('Main tasks')}</p>
          <p className='exp-table__title'>{t('My works results')}</p>
        </div>
        {data.map((job, i) => {
          return (
            <div className='exp-table__row' key={i}>
              <div className='exp-table__organization'>
                <p className='exp-table__organization-title'>{job.title}</p>
                <p className='exp-table__organization-address'>
                  {t(job.address)}
                </p>
                <p className='exp-table__organization-duration'>
                  {t(job.duration)}
                </p>
              </div>
              <div className='exp-table__tasks'>
                <p className='exp-table__title-mobile'>{t('Main tasks')}</p>
                <ul className='exp-table__list'>
                  {job.mainTasks.map((item, a) => {
                    if (item.task && item.stack) {
                      return (
                        <li className='exp-table__item' key={a}>
                          <p>{t(item.task)}</p>
                          <p className='exp-table__item-stack'>{item.stack}</p>
                        </li>
                      );
                    } else {
                      return (
                        <li className='exp-table__item' key={a}>
                          {t(item)}
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
              <div className='exp-table__result'>
                <p className='exp-table__title-mobile'>
                  {t('My works results')}
                </p>
                <ul className='exp-table__list'>
                  {job.resultOfMyWork.map((item, a) => (
                    <li className='exp-table__item' key={a}>
                      {t(item)}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className='experience' id='experience' data-animation>
      <div className='experience__wrapper'>
        <div className='container'>
          <h2 className='experience__title' data-typed-animation>
            {t('Experience')}
          </h2>
          {tableOfJobs(dataOfExperience)}
        </div>
      </div>
    </div>
  );
}

export default Experience;
