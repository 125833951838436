import {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
// import {Helmet} from "react-helmet";
import BtnUp from '../BtnUp/BtnUp.js';
import Header from '../Header/Header.js';
import Banner from '../Banner/Banner.js';
import About from '../About/About.js';
import Skills from '../Skills/Skills.js';
import Experience from '../Experience/Experience.js';
import Portfolio from '../Portfolio/Portfolio.js';
import Pixels from './../Pixels/Pixels.js';
import Contact from './../Contact/Contact.js';

import './App.scss';

const durationAnimation = 500;

function App() {
  const { i18n } = useTranslation();
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const isMounted = useRef(false);

  const setCountCahrs = useCallback(() => {
    document.querySelectorAll('[data-typed-animation]').forEach((element) => {
      element.style.setProperty(
        '--charsCursor',
        Math.round(element.textContent.length * 0.5)
      );
    });
  }, []);

  const showLoader = useCallback(() => {
    const loader = document.getElementById('loader');

    loader.style.setProperty('z-index', 1000);
    setTimeout(() => {
      loader.classList.remove('loaded');
    }, 0);
  }, []);

  const hideLoader = useCallback(() => {
    const loader = document.getElementById('loader');
    loader.classList.add('loaded');

    const transitionEndHandler = () => {
      loader.style.setProperty('z-index', -10);
      loader.removeEventListener('transitionend', transitionEndHandler);
    };

    loader.addEventListener('transitionend', transitionEndHandler);
  }, []);

  useEffect(() => {
    setCountCahrs();
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, [setCountCahrs]);

  useLayoutEffect(() => {
    document.fonts.ready.then(() => {
      hideLoader();
    });
  }, [hideLoader]);

  useEffect(() => {
    const blocks = document.querySelectorAll('[data-animation]');

    const animationType = (el) => {
      const text = el.textContent;
      const typingElement = el;
      let index = 0;
      typingElement.innerHTML = '';
      el.classList.add('animation');

      function typeLetter() {
        if (index < text.length) {
          typingElement.innerHTML += text.charAt(index);
          index++;
          setTimeout(typeLetter, 120); // Регулируйте скорость печатания здесь
        }
      }

      typeLetter();
    };

    const showAnimation = (blocks) => {
      blocks.forEach((target) => {
        const targetPosition = {
            top: window.pageYOffset + target.getBoundingClientRect().top,
            left: window.pageXOffset + target.getBoundingClientRect().left,
            right: window.pageXOffset + target.getBoundingClientRect().right,
            bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
          },
          windowPosition = {
            top: window.pageYOffset,
            left: window.pageXOffset,
            right: window.pageXOffset + document.documentElement.clientWidth,
            bottom: window.pageYOffset + document.documentElement.clientHeight,
          };

        if (
          targetPosition.bottom > windowPosition.top &&
          targetPosition.top < windowPosition.bottom &&
          targetPosition.right > windowPosition.left &&
          targetPosition.left < windowPosition.right
        ) {
          const elTyped = target.querySelectorAll('[data-typed-animation]');

          if (!target.classList.contains('animation') && elTyped) {
            elTyped.forEach((el, i) => {
              if (i) {
                elTyped[i - 1].addEventListener('animationend', () =>
                  animationType(el)
                );
              } else {
                animationType(el);
              }
            });
          }

          target.classList.add('animation');
          target.classList.add('show-animation');
          window.removeEventListener('scroll', this);
        } else {
          target.classList.remove('show-animation');
        }
      });
    };

    const onScroll = () => {
      showAnimation(blocks);
    };

    document.fonts.ready.then(() => {
      window.addEventListener('scroll', onScroll);
      showAnimation(blocks);
    });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (isAnimationComplete) {
      setCountCahrs();
      hideLoader();
    }
  }, [i18n.language, isAnimationComplete, setCountCahrs, hideLoader]);

  const changeLanguage = useCallback(
    (newLanguage) => {
      setIsAnimationComplete(false);
      showLoader();

      document.querySelectorAll('[data-hover]').forEach((element) => {
        element.classList.remove('hover');
      });

      setTimeout(() => {
        if (i18n.language !== newLanguage) i18n.changeLanguage(newLanguage);
        setIsAnimationComplete(true);
      }, durationAnimation + 300);
    },
    [i18n, showLoader]
  );

  return (
    <>
      <Header changeLanguage={changeLanguage} />
      <Banner />
      <About />
      <Pixels rows='6' direction='up'></Pixels>
      <Skills />
      <Pixels rows='6' direction='down'></Pixels>
      <Experience />
      <Pixels rows='6' direction='up'></Pixels>
      <Portfolio></Portfolio>
      <Pixels rows='6' direction='down'></Pixels>
      <Contact></Contact>
      <BtnUp />
    </>
  );
}

export default App;
