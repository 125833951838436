import { useTranslation } from 'react-i18next';
import { Button } from 'react-scroll';
import './Header.scss';
import { useEffect, useState, useRef } from 'react';

const langsArray = ['RU', 'EN', 'ES'];

function Header({ changeLanguage }) {
  const { t, i18n } = useTranslation();
  const [isFixedHeader, setIsFixedHeader] = useState(false);
  const [isOpenHeader, setIsOpenHeader] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    function turnOffMenu() {
      setIsOpenHeader(false);
    }

    function addHover() {
      this.classList.add('hover');
    }

    function removeHover() {
      this.classList.remove('hover');
    }

    function toggleHoverAll(e) {
      if (e.target.closest('[data-hover]')) {
        e.target.closest('[data-hover]').classList.add('hover');
      } else {
        document.querySelectorAll('[data-hover]').forEach((element) => {
          element.classList.remove('hover');
        });
      }
    }

    document.querySelectorAll('.item-link').forEach((element) => {
      element.addEventListener('click', turnOffMenu);
    });

    document.querySelectorAll('[data-hover]').forEach((element) => {
      if (!('ontouchstart' in window || navigator.maxTouchPoints)) {
        element.addEventListener('mouseover', addHover);
        element.addEventListener('mouseout', removeHover);
      } else {
        document.addEventListener('touchend', toggleHoverAll);
      }
    });

    return () => {
      document.querySelectorAll('.item-link').forEach((element) => {
        element.removeEventListener('click', turnOffMenu);
      });

      document.querySelectorAll('[data-hover]').forEach((element) => {
        if (!('ontouchstart' in window || navigator.maxTouchPoints)) {
          element.removeEventListener('mouseover', addHover);
          element.removeEventListener('mouseout', removeHover);
        } else {
          document.removeEventListener('touchend', toggleHoverAll);
        }
      });
    };
  }, []);

  useEffect(() => {
    function handleSetFixedHeader(value) {
      headerRef.current.style.setProperty('opacity', '0');

      setTimeout(() => {
        setIsFixedHeader(value);
        headerRef.current.style.setProperty('opacity', '1');
      }, 200);

      // const onTransitionEnd = function () {
      //   setIsFixedHeader(value);
      //   headerRef.current.style.setProperty('opacity', '1');
      //   // this.removeEventListener('transitionend', onTransitionEnd);
      // };

      // headerRef.current.addEventListener('transitionend', onTransitionEnd);
    }

    const onScroll = () => {
      const shouldFixHeader = document.documentElement.scrollTop > 450;

      if (!isOpenHeader && isFixedHeader !== shouldFixHeader) {
        handleSetFixedHeader(shouldFixHeader);
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isFixedHeader, isOpenHeader]);

  const btn = (lng) => {
    return (
      <button
        className={'lang__btn' + (i18n.language === lng ? ' current' : '')}
        key={lng}
        onClick={
          i18n.language === lng
            ? () => null
            : () => {
                changeLanguage(lng);
                localStorage.setItem('lng', lng);
              }
        }
      >
        {lng}
      </button>
    );
  };

  return (
    <header
      className={`header${
        isFixedHeader || isOpenHeader ? ' header--fixed' : ''
      } ${isOpenHeader ? ' header--open_menu' : ''}`}
      ref={headerRef}
    >
      <div className='container'>
        <div className='header__block'>
          <button
            className={`header__btn${isOpenHeader ? ' open' : ''}`}
            onClick={() => setIsOpenHeader(!isOpenHeader)}
          >
            <span className='line'></span>
            <span className='line'></span>
            <span className='line'></span>
          </button>
          <nav
            className={`header__nav header-nav${isOpenHeader ? ' show' : ''}`}
          >
            <ul className='header-nav__list'>
              <li className='header-nav__item'>
                <Button
                  to='about'
                  smooth={true}
                  duration={1000}
                  offset={-50}
                  className='item-link'
                >
                  {t('About')}
                </Button>
              </li>
              <li className='header-nav__item'>
                <Button
                  to='skills'
                  smooth={true}
                  duration={1000}
                  offset={-50}
                  className='item-link'
                >
                  {t('Skills')}
                </Button>
              </li>
              <li className='header-nav__item'>
                <Button
                  to='experience'
                  smooth={true}
                  duration={1000}
                  offset={-50}
                  className='item-link'
                >
                  {t('Experience')}
                </Button>
              </li>
              {/* <li className='header-nav__item'>
                <Button to='competencies' smooth={true} duration={1000} className='item-link'>
                  {t('Competence')}
                </Button>
              </li> */}
              <li className='header-nav__item'>
                <Button
                  to='portfolio'
                  smooth={true}
                  duration={1000}
                  offset={-50}
                  className='item-link'
                >
                  {t('Portfolio')}
                </Button>
              </li>
              <li className='header-nav__item'>
                <Button
                  to='contacts'
                  smooth={true}
                  duration={1000}
                  offset={-50}
                  className='item-link'
                >
                  {t('Contact')}
                </Button>
              </li>
            </ul>
          </nav>

          <div className='header__lang lang'>
            <div
              className='lang__header'
              style={{ '--count-lng': langsArray.length }}
              data-hover
            >
              {btn(i18n.language)}
              {langsArray.map((lng) =>
                i18n.language !== lng ? btn(lng) : null
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
