import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from '../Popup/Popup';

import './Portfolio.scss';

import dataOfportfolio from './DataPortfolio.json';

function Portfolio() {
  const { t } = useTranslation();

  return (
    <div className='portfolio' id='portfolio' data-animation>
      <div className='portfolio__wrapper'>
        <div className='container'>
          <h2 className='portfolio__title' data-typed-animation>
            {t('Portfolio')}
          </h2>
          <SwiperPortfolio></SwiperPortfolio>
        </div>
      </div>
    </div>
  );
}

function SwiperPortfolio() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataPopup, setDataPopup] = useState({});
  const { t } = useTranslation();

  const openPopup = (dataPopup) => {
    setDataPopup(dataPopup);
    setIsPopupOpen(true);
  };

  const onClosePopup = () => setIsPopupOpen(false);

  const SlisesOfPortfolio = (data) => {
    return data.map((item, i) => {
      return (
        <div className='portfolio__slide' key={i}>
          <div
            className='portfolio__slide-block'
            style={{
              '--img': item.srcBg ? `url(${item.srcBg})` : null,
              '--imgWebp': item.srcBgWebp ? `url(${item.srcBgWebp})` : `url(${item.srcBg})`,
            }}
          >
            <p className='portfolio__slide-title'>{t(item.title)}</p>
            <p className='portfolio__slide-description'>
              {t(item.description)}
            </p>
            <button
              className='portfolio__slide-btn'
              onClick={() => openPopup(item.dataPopup)}
            >
              {t('View Case')}
            </button>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className='portfolio__slider'>
        {SlisesOfPortfolio(dataOfportfolio)}
      </div>
      {isPopupOpen ? (
        <Popup dataPopup={dataPopup} onClosePopup={onClosePopup}></Popup>
      ) : null}
    </>
  );
}

export default Portfolio;
