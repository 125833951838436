import { useEffect, useState, useCallback, useMemo } from 'react';
import './Pixels.scss';

function Pixels({ rows, direction = 'down' }) {
  const [countPixels, setCountPixels] = useState(window.innerWidth / 20);

  useEffect(() => {
    function resizeEvent() {
      setCountPixels(window.innerWidth / 20);
    }

    window.addEventListener('resize', resizeEvent);
    resizeEvent();
    return () => window.removeEventListener('resize', resizeEvent);
  }, []);

  const ItemsOfPixels = useCallback((time) => {
    return [...Array(Math.ceil(countPixels))].map((_el, i) => {
      return (
        <div
          className='pixels__item'
          style={
            ({ animationDelay: Math.ceil(Math.random() * time) + 'ms', opacity:  Math.random() * ((time - 1000) / 10000)  })
          }
          key={i}
        ></div>
      );
    });
  }, [countPixels]);

  const BlockOfPixels = useCallback(() => {
    const rowsNumber = Number(rows);
    return [...Array(rowsNumber)].map((el, i) => {
      let incOfDelay = rowsNumber - i;

      if (direction === 'up') {
        incOfDelay = i + 1;
      }

      return (
        <div className='pixels__block' key={i}>
          {ItemsOfPixels(10000 + 1000 * incOfDelay * incOfDelay)}
        </div>
      );
    });
  }, [ItemsOfPixels, direction, rows]);

  return <div data-animation className={'pixels ' + direction}> {useMemo(() => BlockOfPixels(), [BlockOfPixels])}</div>;
}

export default Pixels;
